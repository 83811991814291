.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0px;
}

body {
    margin: 0;
    font-family: Open Sans, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #525f7f;
    text-align: left;
    background-color: #f8f9fe;
}

.main-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.react-calendar {
    border: none !important;
}

.rectangle {
    border-radius: 5px;
    background-color: #e6e8f2;
    padding: 13px 8px;
    color: #606168 !important;
    border: none !important;
    cursor: pointer;
}
.expanded-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}
abbr[title],
abbr[data-original-title] {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.margin-5 {
    margin: 5px;
}

.margin-left-15 {
    margin-left: 15px;
}

.row-centered {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.react-calendar__month-view__days__day--weekend {
    color: inherit !important;
}

.react-calendar__tile--rangeStart {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}

.react-calendar__tile--rangeEnd {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}

.react-calendar button {
    margin: 5px 0px;
    height: 30px;
    border: 0;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-calendar__tile--active {
    color: white !important;
}
.react-calendar__tile--now {
    background-color: white;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background-color: #aecbfa !important;
}

.react-calendar__tile:disabled {
    color: lightgray !important;
    background-color: white;
}

.react-calendar__tile--range {
    background-color: #d2e3fc;
    color: #185abc !important;
}

.react-autosuggest__container {
    position: relative;
    width: 300px;
    margin-left: 10px;
}

.react-autosuggest__input {
    border-radius: 5px;
    background-color: #e6e8f2;
    padding: 13px 8px;
    color: #606168 !important;
    border: none !important;
    width: 100%;
}

.react-autosuggest__suggestions-container {
    position: absolute;
    background-color: white;
    width: 100%;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 4px;
}

.react-autosuggest__suggestion:hover {
    background-color: #eeee;
}
.react-autosuggest__suggestions-container ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.site-mode-selector {
    box-sizing: border-box;
    height: 34px;
    width: 79px;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    cursor: pointer;
}

.site-mode-selector--selected {
    height: 34px;
    width: 79px;
    border-radius: 5px;
    background-color: #4285f4;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    cursor: pointer;
}

.navbar-top {
    /* important for the autocomplete */
    z-index: 1000;
}

.nav-link {
    cursor: pointer;
}

.autocomplete-dropdown-container {
    position: absolute;
    z-index: 10000;
    width: 90%;

    /* border: 1px solid #dee2e6; */
    border-radius: 4px;
    overflow: hidden;
}

.suggestion-item,
.suggestion-item--active {
    padding: 5px;
    background-color: #fafafa !important;
}

.suggestion-item--active {
    background-color: #eeeeee !important;
}

.tooltip-top {
    background-color: #fff !important;
    color: #000;
}

.rst__rowLabel {
    flex: 1;
}

.react-kanban-column {
    background-color: white;
}

.react-kanban-card {
    border: 1px solid grey;
}

.react-kanban-card-adder-button {
    display: none;
}

#slide-mask-editor {
    position: absolute;
    right: -290px;
    top: 0;
    height: 100%;
    width: 290px;
    padding: 115px 20px 35px 20px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
    /* -webkit-animation: slide 1s forwards; */
    animation: slide 1s forwards;
}

@-webkit-keyframes slide {
    100% {
        right: 0;
    }
}

@keyframes slide {
    100% {
        right: 0;
    }
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    font-weight: bold;
}

#setup-container .MuiTableCell-root {
    border: none;
}

.MuiTimelineItem-missingOppositeContent:before {
    flex: none !important;
}
.sigCanvas {
    border: 1px solid black;
    border-radius: 4px;
    margin-bottom: 10px;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 3px 2px rgb(233 236 239 / 5%);
}

/** MODAL */
.modal-title {
    font-size: 1.0625rem;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}
.fade {
    transition: opacity 0.15s linear;
}
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal.show .modal-dialog {
    transform: none;
}
.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
}
.fade.show .modal-dialog {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: show;
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.2);
    border-radius: 0.4375rem;
    box-shadow: 0 15px 35px rgb(50 50 93 / 20%), 0 5px 15px rgb(0 0 0 / 17%);
    outline: 0;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1.25rem;
    border-bottom: 0 solid #e9ecef;
    border-top-left-radius: 0.4375rem;
    border-top-right-radius: 0.4375rem;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1.5rem;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.modal-backdrop.show {
    opacity: 0.16;
}

.modal-backdrop.fade {
    opacity: 0;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-content {
        box-shadow: 0 15px 35px rgb(50 50 93 / 20%), 0 5px 15px rgb(0 0 0 / 17%);
    }
}
@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

/** FORM */
.form-row > .col,
.form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}
.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}
.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
.mb-6,
.my-6 {
    margin-bottom: 4.5rem !important;
}
@media (min-width: 768px) {
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}
button.close {
    padding: 0;
    background-color: initial;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.close {
    transition: all 0.15s ease;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1;
    color: rgba(0, 0, 0, 0.6);
    text-shadow: none;
    opacity: 0.5;
}
.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
    opacity: 0.75;
}
.modal-header .close {
    padding: 1.25rem;
    margin: -1rem -1rem -1rem auto;
}
.input-group-append {
    margin-left: -1px;
}
.input-group-append,
.input-group-prepend {
    display: flex;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.input-group {
    box-shadow: 0 3px 2px rgb(233 236 239 / 5%);
    border-radius: 0.25rem;
    transition: all 0.15s ease-in-out;
}
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1;
    min-width: 0;
    margin-bottom: 0;
}
.input-group-append {
    margin-left: -1px;
}
.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.625rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #adb5bd;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
}
@media (min-width: 576px) {
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}
.btn {
    position: relative;
    text-transform: none;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    font-size: 0.875rem;
    will-change: transform;
}
.btn {
    display: inline-block;
    font-weight: 600;
    color: #525f7f;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: initial;
    border: 1px solid transparent;
    padding: 0.625rem 1.25rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1;
    min-width: 0;
    margin-bottom: 0;
}
.input-group .form-control,
.input-group .form-control:focus {
    box-shadow: none;
}
.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.btn-primary,
.btn-primary:hover {
    color: #fff;
    background-color: #5e72e4;
    border-color: #5e72e4;
}
.btn-primary {
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: initial;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    margin-bottom: 30px;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
    border: 0;
}
.card-header {
    padding: 1.25rem 1.5rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.5rem;
}
.react-kanban-column {
    background-color: transparent !important;
}

.col {
    display: flex;
    flex-direction: column;
}
@media (min-width: 768px) {
    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media only screen and (max-width: 700px) {
    .react-autosuggest__container {
        position: relative;
        width: auto;
        margin-left: 10px;
    }
}
